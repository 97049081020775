.orderBump {
  border: 2px solid #CD201F;
  background: #FFEEF1;
  min-height: 166px;
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 10px;
  max-width: 371px;

  .pOferta {
    font-weight: 700;
    color: #29272E;
    font-size: 20px;
    margin: 0 0 15px 0;
    width: 100%;
    text-align: center;
  }

  .oferta {
    display: flex;
    justify-content: flex-start;
    padding: 0 20px;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 10px;

    &.disabled {
      cursor: inherit;
      opacity: 0.5;
    }

    .bumpInfo {
      margin-left: 10px;
      width: 90%;

      .bumpName {
        font-weight: 500;
        font-size: 12px;
        color: #29272E;
        display: block;
        margin: 0 0 3px 0;
      }

      .bumpDescription {
        font-size: 12px;
        color: #666666;
        font-weight: 400;
        display: block;
        margin: 8px 0 5px 0;
      }

      .bumpPrice {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        color: #ffffff;
        background: #666666;
        height: 23px;
        width: 85px;

        &.old {
          text-decoration: line-through;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
        }

        small {
          color: var(--color-primary);
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .checkbox {
    cursor: pointer;
    margin-top: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;

    &.selected {
      background-color: var(--color-primary);
      border: 1px solid var(--color-primary);

      span {
        font-size: 0.65rem;
        margin-bottom: 1px;
        color: #fff;
      }
    }

    &.notSelected {
      background-color: #ffffff;

      span {
        display: none;
      }
    }

    &.all {
      margin-left: 20px;
    }
  }

  .expireIn {
    width: 100%;
    text-align: center;
    margin: 10px 0;

    p {
      margin: 0;
      font-weight: 500;
      color: #EB5757;
      font-size: 15px;
    }
  }

  .selecionarTodos {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    height: 42px;
    cursor: pointer;
    background: linear-gradient(224.47deg, #FF92AE 8.18%, #FF3D9A 95.84%);
    margin-top: 20px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;

    .selecionar {
      margin-left: 10px;
      font-size: 14px;
      color: #fff;
    }
  }
}
