.cart-item-container {
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  border-radius: 5px;
  padding: 0 1rem 0 2rem;
  margin-bottom: 1rem;

  @media (max-width: 992px) {
    flex-direction: column;
    padding: 0 1rem 1rem 1rem;
  }

  .cart-item-product {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    width: 60%;

    .product-image {
      height: auto;
      width: 64px;
    }

    p {
      padding: 1rem 2rem 1rem 1rem;
      font-size: 0.8rem;
      overflow: hidden;
      color: #666666;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: flex;
      flex-direction: column;

      span {
        display: block;
      }

      small {
        color: #A0AEC0;
        font-size: 11px;
        margin-top: 5px;
        display: block;
      }
    }

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  .center {
    justify-content: center !important;
  }

  .cart-item-quantity-value {
    display: flex;
    justify-content: space-between;
    width: 40%;

    @media (max-width: 992px) {
      align-items: center;
      width: 100%;
    }
  }

  .cart-item-quantity {
    text-align: center;
    width: 35%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .trash-icon {
      display: none;
      margin-right: auto;
      margin-left: 1rem;
    }

    .remove-text {
      font-size: 0.9rem;
      color: #3754DB;
      cursor: pointer;
      margin: 1.2rem 0 0.5rem 0;
    }

    .add-or-remove {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-primary);
      small {
        color: #727272;
        font-weight: 600;
        font-size: 0.9rem;
      }

      img, svg {
        color: var(--color-primary);
        cursor: pointer;
        height: 1.25rem;
        width: 1rem;
        margin: 0 1rem;
      }
    }

    @media (max-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      zoom: 0.9;

      .remove-text {
        display: none;
      }

      .trash-icon {
        display: block;
      }
    }
  }

  .cart-item-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 992px) {
      width: 100%;
      zoom: 0.9;
      margin-right: -1rem;
    }

    .cart-item-content {
      .price-without-discount {
        color: #666666;
        text-decoration: line-through;
        font-size: 0.8rem;
        margin-bottom: 5px;
      }

      .total-price {
        color: #29272E;
        font-weight: 500;
        font-size: 1.25rem;
      }

      .discount-percent {
        margin-left: 0.35rem;
        font-size: 0.8rem;
        font-weight: 600;
        color: var(--color-primary);
      }

      .price-before {
        color: red;
        font-size: 12px;
        margin: 5px 0px 0px 0px;
      }
    }
  }
}
