.form {
  display: flex;
  justify-content: space-between;

  .align {
    margin-top: 15px;

    p {
      font-size: 14px;
      font-weight: 500;
    }

    .information {
      p {
        color: #425466;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 8px;
      }

      input {
        width: 318px;
        height: 36px;
        border-radius: 6px;
        border: none;
        box-shadow: 0 1px 5px 0 #e3e1e1;
        padding: 0 0 0 8px;

        @media(max-width: 769px) {
          width: 100%;
        }
      }

      input:focus {
        outline: 0;
      }

      select {
        width: 318px;
        height: 36px;
        border-radius: 6px;
        border: none;
        box-shadow: 0 1px 5px 0 #e3e1e1;
        padding: 0 0 0 8px;
        background: #fff;
        margin-bottom: 20px;

        @media(max-width: 769px) {
          width: 100%;
        }
      }

      select:focus {
        outline: 0;
      }
    }

    .group {
      display: flex;
      justify-content: space-between;

      input {
        width: 140px;
        height: 36px;
        border-radius: 6px;
        border: none;
        box-shadow: 0 1px 5px 0 #e3e1e1;
        padding: 0 0 0 8px;

        @media(max-width: 769px) {
          width: 100%;
        }
      }
    }
  }
}

.options {
  margin-bottom: 15px;

  .card {
    width: 100%;
    height: 68px;
    border: 2px solid #BDBDBD;
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 3px 0 #ababab4f;
    justify-content: space-between;

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .source {
        display: flex;

        p {
          font-size: 14px;
          font-weight: 500;
          color: #29272E;
        }

        img {
          margin-right: 16px;
          margin-left: 28px;
        }
      }
    }

    img {
      margin-right: 15px;
    }
  }


  .active {
    width: 100%;
    height: 465px;
    border: 2px solid #3754DB;
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    box-shadow: 0 1px 3px 0 #ababab4f;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-top: 10px;

      .source {
        display: flex;

        p {
          color: #3754DB;
          font-size: 14px;
          font-weight: 500;
        }

        img {
          margin-right: 16px;
          margin-left: 28px;
        }
      }

      .arrow {
        margin-right: 15px;
        transform: rotate(180deg);
        animation: rotateOpen 1s;
      }
    }

    .align {
      display: flex;
      flex-direction: column;
      padding: 7px;

      .onlyMobile {
        @media(max-width: 769px) {
          display: flex;
          gap: 20px;

          .inputMobile {
            width: 133px;
          }
        }
      }

      .information {
        width: 98%;

        p {
          color: #425466;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 8px;
        }

        input {
          width: 100%;
          height: 36px;
          border-radius: 6px;
          border: none;
          box-shadow: 0 1px 5px 0 #e3e1e1;
          padding: 0 0 0 8px;

        }

        input:focus {
          outline: 0;
        }

        select {
          width: 100%;
          height: 36px;
          border-radius: 6px;
          border: none;
          box-shadow: 0 1px 5px 0 #e3e1e1;
          padding: 0 0 0 8px;
          background: #fff;
          margin-bottom: 20px;
        }

        select:focus {
          outline: 0;
        }

      }
    }
  }

  @keyframes rotateOpen {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

}
