.my-addresses-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.5rem;

  h1 {
    font-size: 1.75rem;
  }

  @media (max-width: 768px) {
    display: block;
    max-width: 100%;

    h1 {
      text-align: center;
      padding: 0 1rem;
      font-size: 1.5rem;
      margin: 0;
    }
  }

  .btn {
    margin-top: 1.5rem;
  }

  .btn-register {
    width: 200px;
    margin: auto;
  }

  .inputs-row {
    display: flex;
    margin: 1.5rem 0;

    .input-form {
      width: 100%;
      margin: 0 !important;
    }

    .input-form:first-child {
      width: 30%;
      margin-right: 1rem !important;
    }
  }

  .zipcode-row {
    display: flex;
    align-items: center;

    div:first-child {
      width: 65%;

      @media (max-width: 768px) {
        width: 65%;
      }
    }

    .search-cep {
      margin: 2rem auto 0 auto;
      text-decoration: none;

      color: #3754DB;
      font-weight: 500;
      font-size: 0.775rem;
    }
  }

  .address-state {
    margin: 2rem 0 3rem;
    font-weight: 500;
    font-size: 0.875rem;
  }
}
