.form {
  display: flex;
  justify-content: space-between;

  .align {
    margin-top: 15px;
    @media(max-width: 971px) {
      width: 100%;
    }

    p {
      font-size: 14px;
      font-weight: 500;
    }


    .information {
      p {
        color: #425466;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 8px;
      }

      input {
        width: 318px;
        height: 36px;
        border-radius: 6px;
        border: none;
        box-shadow: 0 1px 5px 0 #e3e1e1;
        padding: 0 0 0 8px;

        @media(max-width: 769px) {
          width: 100%;
        }
      }

      input:focus {
        outline: 0;
      }

      .reference {
        width: 673px;
        margin-bottom: 15px;
        @media(max-width: 769px) {
          width: 100%;
        }
      }

      .numberText {
        @media(max-width: 769px) {
          display: none;
        }
      }
    }

    .group {
      display: flex;
      gap: 30px;
      align-items: center;

      @media(max-width: 769px) {
        display: block;
      }

      .mobileAddress {
        display: none;
        @media(max-width: 769px) {
          display: flex;
          gap: 28px;
        }

        .numberMobile {
          width: 109px;
        }

        .completeMobile {
          width: 150px;
        }
      }

      .cep {
        color: #3754DB;
        font-size: 12px;
        cursor: pointer;
        @media(max-width: 769px) {
          margin-left: 12px;
        }
      }

      .address {
        width: 505px;
        @media(max-width: 769px) {
          width: 100%;
        }
      }

      .number {
        width: 130px;
        @media(max-width: 769px) {
          display: none;
        }
      }

      .completeText {
        display: block;
        @media(max-width: 769px) {
          display: none;
        }
      }

      .complete {
        display: block;
        width: 191px;
        @media(max-width: 769px) {
          display: none;
        }
      }

      .info {

        span {
          font-size: 14px;
          font-weight: 500;
        }

        .burgh {
          margin-right: 27px;
          width: 280px;
          @media(max-width: 769px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 21px;
          }
        }

      }

      input {
        width: 208px;
        height: 36px;
        border-radius: 6px;
        border: none;
        box-shadow: 0 1px 5px 0 #e3e1e1;
        padding: 0 0 0 8px;

        @media(max-width: 769px) {
          width: 180px;
        }
      }
    }
  }
}
