.register {
  padding-bottom: 10px;
  text-align: center;

  h1 {
    font-size: 1.75rem;
  }

  @media (max-width: 769px) {
    display: block;
    width: 100%;
    padding-top: 15px;
    h1 {
      text-align: center;
      padding: 0 1rem;
      font-size: 1.3rem;
      margin: 0;
    }
  }
}

.content {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  gap: 2rem;
  padding-bottom: 2rem;

  @media(max-width: 769px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .agroupment {
    .backPage {
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      color: #29272E;
      padding: 0.5rem;
      margin-right: 1rem;

      img {
        margin-right: 8px;
      }
    }

    .arrowOpen {
      border: none;
      background: none;
      font-size: 14px;
      padding: 0;
      color: #29272E;
      cursor: pointer;
      margin-top: 36px;

      img {
        padding: 0 6px;
      }
    }

    .boxLogin {
      background-color: #FFEEF1;
      width: 100%;
      height: 177px;
      max-width: 375px;
      margin: 12px 0 0;
      border-radius: 5px;
      padding: 1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 2px solid #CD201F;

      .title {
        color: #29272E;
        font-weight: 700;
        font-size: 20px;
        margin: 8px 0 14px;
      }

      .check {
        label {
          display: flex;
          align-items: center;

          input {
            cursor: pointer;
          }

          p {
            text-decoration: none;
            color: #29272E;
            font-size: 13px;
            padding-left: 9px;
            margin: 0;
          }
        }

        .price {
          margin-left: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .discount {
            font-size: 12px;
            margin-bottom: 2px;
            color: #666666;
          }

          .value {
            display: flex;
            align-items: center;
            margin-top: 0;
            margin-bottom: 12px;
            font-size: 20px;
            font-weight: 700;

            span {
              font-size: 12px;
              font-weight: 400;
              margin-left: 4px;
              color: var(--color-primary);
            }
          }
        }
      }

      .time {
        color: #EB5757;
        font-weight: 500;
      }
    }

    @keyframes show {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .boxPayment {
      width: 375px;
      height: auto;
      border-radius: 5px;
      background-color: #FFFFFF;
      margin-top: 1rem;
      margin-bottom: 10px;

      @media(max-width: 769px) {
        max-width: 769px;
        width: 100%;
        margin-bottom: 0;
      }

      .option {
        display: flex;
        justify-content: space-between;
        padding: 0 25px 0 0;
        cursor: pointer;
        align-items: center;
        height: 75px;
        border-top: 1px solid #F2F2F2;
        border-bottom: 1px solid #F2F2F2;

        .group {
          display: flex;
          margin: 25px;

          .pix {
            padding-right: 10px;
          }
          .pixparcelado {
            width: 56px;
            margin-left:-18px;
          }
          p {
            display: flex;
            flex-direction: column;
            padding-left: 16px;
            font-weight: 500;

            span {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              color: #666666;
            }
          }
        }

        &.active {
          background-color: var(--color-secondary-background);
          border-width: 1px 1px 0px 1px;
          border-style: solid;
          border-color: var(--color-primary);
        }
      }

      .result {
        animation: show .5s;
        padding: 4px 0 8px 0;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: var(--color-primary);

        .information {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;

          &.noMargin {
            margin:0px;
            img {
              width: 100%;
            }
          }

          .informationPix {

            display: flex;
            justify-content: center;
            align-items: center;

            img {margin-left: 10px;}
            span {font-size: 14px;font-weight: 500;color:#425466;}
          }

          .info {

            display: flex;
            justify-content: space-between;
            flex-direction: column;


            .align {
              margin-top:20px;
              display: flex;
              flex-direction: column;
              text-align: center;
              align-items: center;


              p {
                font-size: 14px;
                font-weight: 500;
                margin:5px;
              }

              span {
                font-size: 12px;
                color: #666666;
                width: 216px;
                @media(max-width: 769px) {
                  width: 150px;
                }
              }
            }
          }
        }

        p {
          margin: 16px 28px 9px;
          font-weight: 500;
          font-size: 14px;
          color: #425466;
        }

        .infoRecurring {
          font-size: 12px;
          color: #666666;
          font-weight: 400;
        }

        select {
          font-weight: 500;
          font-size: 13px;
          &.installments {
            margin-bottom: 5px;
          }
        }


        input {
          width: 308px;
          height: 36px;
          border: none;
          border-radius: 6px;
          font-weight: 500;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
          padding-left: 9px;
          margin: 0 28px 22px;
          @media(max-width: 769px) {
            max-width: 308px;
            width: 95%;
          }
          @media(max-width: 400px) {
            max-width: 308px;
            width: 82%;
          }
        }

        input:focus {
          outline: 0;
        }

        select {
          width: 308px;
          height: 36px;
          border: none;
          border-radius: 6px;
          font-weight: 500;
          background: none;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
          padding-left: 9px;
          margin: 0 28px 43px;
          @media(max-width: 769px) {
            max-width: 308px;
            width: 95%;
          }

          @media(max-width: 400px) {
            max-width: 308px;
            width: 82%;
          }

          select:active {
            outline: 0;
          }
        }
      }

      .align {
        display: flex;

        @media(max-width: 300px) {
          flex-direction: column;
        }

        .validate {
          width: 133px;
          margin-right: 1px;

          @media(max-width: 400px) {
            width: 115px;
            margin-right: 1px;
          }
        }

        .code {
          width: 142px;
          @media(max-width: 400px) {
            width: 115px;
          }
        }
      }
    }

    @media (max-width: 769px) {
      margin: 15px;
      width: 90%;
    }
  }

  .cartResume {
    @media(max-width: 769px) {
      width: 90%;
    }
  }

  .boxTable {
    width: 100%;
    max-width: 350px;
    height: auto;
    border-radius: 5px;
    background-color: #FFFFFF;
    margin-top: 2.25rem;

    @media(max-width: 769px) {
      margin-top: 0;
      max-width: 768px;
    }

    .title {
      display: flex;
      align-items: center;
      background-color: #FAFAFB;
      width: 350px;
      height: 46px;

      p {
        font-size: 10px;
        color: #8492A6;
        font-weight: 500;
      }

      .product {
        margin: 15px 144px 15px 20px;
      }

      .qtd {
        margin: 15px 60px 15px 0;
      }

      .value {
        margin: 15px 21px 15px 0;
      }

      @media(max-width: 769px) {
        width: 100%;
        .product {
          padding-left:15px;
          margin: 15px 0;
          width: 60%;
        }

        .qtd {
          margin: 15px 0;
          width: 20%;
        }

        .value {
          padding-left:15px;
          margin: 15px 0;
          width: 20%;
        }
      }
    }

    table {
      .product {
        font-size: 10px;
        padding: 20px 60px 23px 20px;
        font-weight: 400;
        color: #29272E;
        line-height: 1.5;
        width: 130px;
      }

      .qtd {
        font-size: 12px;
        padding: 27px 47px 27px 0px;
        font-weight: 400;
      }

      .value {
        white-space: nowrap;
        font-size: 12px;
        padding: 27px 13px 27px 0px;
        font-weight: 400;
      }

      .oldPrice {
        text-decoration: line-through;
        margin-bottom: 0px;
      }

      .newPrice {
        margin-top: 0px;
      }

      .priceGroup{
        display: flex;
        flex-direction: column;

        .realPrice {
          color: #666;
          font-size: 10px;
          span{
            text-decoration: line-through;
          }
        }
      }

      .freight {
        font-size: 12px;
        padding: 5px 0px 0px 0px;
        border-top: 2px solid #FAFAFB;
        border-collapse: collapse;
      }

      th {
        color: #8492A6;
      }
    }

    .shipping {
      border-top: 2px solid #FAFAFB;
      display: flex;
      justify-content: space-between;

      .text {
        font-size: 12px;

        .send {
          padding-left: 20px;
          font-weight: 500;
        }

        .address {
          display: flex;
          align-items: center;

          p {
            color: #666666;
            padding-left: 20px;
            max-width: 18ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 400;
          }

          span {
            font-size: 10px;
            color: #3754DB;
            padding-left: 3px;
            font-weight: 400;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .value {
        white-space: nowrap;
        font-size: 12px;
        padding: 12px 17px 0 0;
        font-weight: 400;

        .linethrough {
          font-size:11px;
          text-decoration: line-through;
        }
      }
    }

    .discount {
      border-top: 2px solid #FAFAFB;
      height: 49px;

      .text {
        display: flex;
        font-size: 12px;
        font-weight: 500;
        height: 0px;
        padding: 0px 0px 9px 19px;

        .value {
          white-space: nowrap;
          font-size: 12px;
          padding-left: 215px;
          font-weight: 400;
        }
      }
    }

    .total {
      background-color: #FAFAFB;
      font-size: 12px;
      font-weight: 500;
      min-height: 46px;

      &.discount {
        background-color: #ffffff;
        border-top: 2px solid #FAFAFB;
      }

      display: flex;
      padding: 0px 20px;
      justify-content: space-between;
      height: 100%;
      align-items: center;

      p {
        margin: 0px;
        color: #29272E;
      }

      button {
        background: none;
        border: none;
        color: #ec0000;
        cursor: pointer;
        font-size: 11px;
      }

      .value {
        font-weight: normal;
      }
    }
  }

  .cupomContent {
    margin: 36px 0px;
    padding-left: 20px;

    @media(max-width: 300px) {
      padding-left: 0;
      input {
        width: 125px;
      }
    }

    .cupomBtn {
      border: none;
      background: none;
      color: #29272E;
      font-size: 14px;
      display: flex;
      padding: 0;
      margin-bottom: 11px;
      cursor: pointer;
      align-items: center;

      .arrowCupom {
        margin-left: 6px;
        animation: rotateClose 1s;
      }

      .rotateImg {
        margin-left: 6px;
        transform: rotate(180deg);
        animation: rotate 1s;
      }

      @keyframes rotate {
        to {
          transform: rotate(180deg);
        }
        from {
          transform: rotate(0deg);
        }
      }

      @keyframes rotateClose {
        to {
          transform: rotate(0deg);
        }
        from {
          transform: rotate(180deg);
        }
      }

      @keyframes show {
        to {
          opacity: 1;
        }
        from {
          opacity: 0;
        }
      }
    }

    .cupomAsk {
      font-size: 14px;
      color: #29272E;
      cursor: pointer;
    }

    .cupom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      animation: show 1.5s;

      label {
        font-size: 12px;
        font-weight: 500;
        color: #29272E;
      }

      .cupomFormGroup {
        display: flex;
        flex-direction: row;
        padding: 8px;
        background-color: #fff;
        justify-content: space-between;

        input {
          border: none;
          color: #666666;

          &:focus {
            outline: none;
          }
        }

        button {
          color: #666666;
          border: 1px solid #FCD64B;
          background-color: transparent;
          height: 26px;
          width: 66px;
          border-radius: 4px;
          font-weight: 600;
          font-size: 10px;
          cursor: pointer;
          outline: none;

          &:disabled {
            opacity: 0.5;
            cursor: inherit;
          }
        }
      }
    }
  }

  .cashback {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:5px 15px;
    background: #ffffff;
    margin-top:1.175rem;
    p {
      color: var(--color-primary);
      font-weight: 500;
      font-size: 14px;
    }
    button {
      color: #666666;
      border: 1px solid #FCD64B;
      background-color: transparent;
      height: 26px;
      width: 66px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 10px;
      cursor: pointer;
      outline: none;

      &:disabled {
        opacity: 0.5;
        cursor: inherit;
      }
    }
  }

  .buttonFinish {
    border: none;
    background-color: #FCD64B;
    color: #666666;
    font-weight: 500;
    font-size: 14px;
    height: 46px;
    margin-top: 18px;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: wait;
    }
  }
}

.error {
  margin: -16px 32px 30px;
  color: red;
  display: flex;
  font-size: 0.625rem;
  padding-top: 0.5rem;
}

.btnOtherInformation {
  border: none;
  background: none;
  color: #666666;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;

  @media(max-width: 769px) {
    font-size: 15px;
  }

}

.showOtherInformation {
  display: block;

  @media(max-width: 769px) {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
  }

}

.hideOtherInformation {
  display: none;
}

.hideLightningDiscount {
  display: none;
}

.showLightningDiscount {
  background: #CD201F;
  height: 120px;
  position: relative;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

  .divImageThunder {
    display: flex;
    align-items: center;
    margin-top: 10px;

    img {
      margin-right: 14px;
    }

    label {
      color: #FFFFFF;
      font-size: 16px;
      font-weight: bold;
    }

  }

  .divPriceDiscount {
    border-radius: 4px;
    background: #FFFFFF;
    width: 283px;
    height: 47px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    justify-content: center;

    p {
      color: #16192C;
      font-size: 14px;
      font-weight: 800;
      padding: 6px;

      span {
        font-size: 10px;
      }
    }
  }

  .differenceDiscount {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
  }

}


