.pix {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 60px;
    margin-bottom: 42px;
  }

  .info {
    gap: 15px;
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;

    .align {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      p {
        font-size: 14px;
        font-weight: 500;
      }

      span {
        font-size: 12px;
        color: #666666;
        width: 216px;
        @media(max-width: 768px) {
          width: 150px;
        }
      }
    }

    .alignSecurity {
      @media(max-width: 768px) {
        display: none;
      }
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      p {
        font-size: 14px;
        font-weight: 500;
      }

      span {
        font-size: 12px;
        color: #666666;
        width: 216px;
      }
    }
  }

  .alignMobile {
    display: none;
    @media(max-width: 768px) {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      margin-bottom: 20px;
      p {
        font-size: 14px;
        font-weight: 500;
      }

      span {
        font-size: 12px;
        color: #666666;
        width: 216px;
        @media(max-width: 768px) {
          width: 150px;
        }
      }
    }
  }
}
