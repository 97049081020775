.header {
  display: flex;
  height: 90px;
  align-items: center;
  justify-content: center;
  @media(max-width: 1111px) {
    height: 70px;
    align-items: flex-end;
  }

  .align {
    max-width: 1111px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    @media(max-width: 1111px) {
      justify-content: center;
    }
  }
}
