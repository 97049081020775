.payment-resume-container {
  border: 1px solid #d6d6d6;
  padding: 1.25rem 1.25rem;

  .product-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .product-details {
      display: flex;

      img {
        height: 60px;
      }

      .product-description {
        margin-left: 1.5rem;
        max-width: 400px;
        width: 100%;
        font-size: 0.875rem;

        p {
          opacity: 0.7;
          font-size: 0.725rem;
          margin-top: 0.25rem;
        }

        @media screen and (max-width: 768px) {
          span {
            font-size: 0.875rem;
            overflow: hidden;
            max-width: 150px;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    .product-value {
      display: flex;
      flex-direction: column;
      align-items: center;

      p:first-child {
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }

      .price-center {
        margin: auto;
      }

      .price-show-de-por {
        text-align: right;
        line-height: 1;
        small {
          font-size: 0.75rem;
          margin-left: 0.25rem;
          text-decoration: line-through;
          font-weight: normal;
        }
      }
    }
  }

  .resume-prices {
    margin-top: 3rem;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.75rem;

      span:first-child:not(.price) {
        font-weight: 400;
        font-size: 0.875rem;
      }
    }

    .divisor {
      border: 1px solid #e1e1e1;
      margin-top: 1.5rem;
    }

    .total-price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        margin-bottom: 0.25rem;
      }

      p {
        font-size: 0.875rem;
      }
    }
  }
}

.green-text {
  color: #62B62A;
}

.price {
  font-size: 1.2rem;
  font-weight: bold;
}
