@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
  font-family: "Roboto", sans-serif;
}

.register {
  padding-bottom: 10px;
  text-align: center;

  h1 {
    font-size: 1.75rem;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding-top:15px;
    padding-bottom: 30px;
    h1 {
      padding: 0 1rem;
      font-size: 1.3rem;
      margin: 0;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;

  form {
    padding: 0px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    background-color: #fff;

    .boxLogin {
      background-color: #fff;
      height: auto;
      border-radius: 5px;
      width: 100%;

      h3 {
        text-align: center;
        font-size: 20px;
        padding-top: 30px;
      }

      .logo {
        display: block;
        margin-bottom: 15px;
      }

      h1 {
        margin: 0;
        width: 313px;
        font-size: 22px;
        font-weight: normal;
        line-height: 1.5;
        text-align: center;
        color: #4a4a4a;
        margin-bottom: 29px;
      }

      .align {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .address {
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s, opacity 0.5s linear;
          overflow: hidden;
          height: 1px;
          &.show {
            visibility: visible;
            opacity: 1;
            height: auto;
            padding-bottom: 5px;
          }
        }

        .error {
          display: flex;
          color: red;
          font-size: 10px;
          padding: 7px;
        }

        p {
          margin-top: 30px;
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 14px;
          color: #425466;
          width: 100%;
        }

        input {
          width: 100%;
          height: 36px;
          border: none;
          border-radius: 6px;
          font-weight: 500;
          font-size: 13px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
          padding-left: 9px;

          @media(max-width: 769px) {
            width: 100%;
            max-width: 312px;
          }
          @media(max-width: 360px) {
            max-width: 260px;
            width: 100%;
          }
        }

        input:focus {
          outline: 0;
        }
      }

      .cep {
        width: 100%;

        input {
          width: 208px;

          @media(max-width: 769px) {
            width: 100%;
            max-width: 208px;
          }
          @media(max-width: 360px) {
            width: 100%;
            max-width: 165px;
          }
        }

        a {
          font-weight: 400;
          font-size: 12px;
          margin-left: 12px;
          color: #3754DB;
          cursor: pointer;
        }
      }

      .group {
        display: flex;
        width: 100%;

        .number {
          width: 94px;
          margin-right: 27px;
        }

        .complete {
          width: 175px;
          @media(max-width: 390px) {
            width: 155px;
          }
          @media(max-width: 360px) {
            width: 100%;
            max-width: 130px;
          }
        }

        .state {
          width: 54px;
          margin-right: 27px;
        }
      }

      .formGroup {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        align-items: center;

        button {
          width: 131px;
          height: 46px;
          border-radius: 8px;
          border: none;
          cursor: pointer;
          background-color: #FCD64B;
          font-weight: 500;
          font-size: 14px;
          color: #666666;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;

          img {
            margin-left: 6px;
          }
        }

        button:hover {
          background: #ffe68a;
          transition: all 0.3s;
        }
      }
    }
  }
  .outsideFormGroup {
    display: flex;
    flex-direction: column;
    margin-top: 31px;
    align-items: center;

    a {
      width: 131px;
      height: 46px;
      border-radius: 8px;
      background-color: transparent;
      cursor: pointer;
      border: 1px solid #fcd64b;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a:hover {
      background: #ffe68a;
      transition: all 0.3s;
    }
  }
}
