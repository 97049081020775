.discountLightning {
  background-color: #CD201F;
  color: #ffffff;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.showLightningDiscount {
  background: #CD201F;
  min-height: 120px;
  position: relative;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

  .divImageThunder {
    display: flex;
    align-items: center;
    margin-top: 10px;

    img {
      margin-right: 14px;
    }

    label {
      color: #FFFFFF;
      font-size: 16px;
      font-weight: bold;
    }

  }

  .divPriceDiscount {
    border-radius: 4px;
    background: #FFFFFF;
    width: 283px;
    height: 47px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    justify-content: center;

    p {
      color: #16192C;
      font-size: 14px;
      font-weight: 800;
      padding: 6px;

      span {
        font-size: 10px;
      }
    }
  }

  .differenceDiscount {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
  }

}
