.big2x-icon {
  height: 64px !important;
  width: 64px !important;
}

.big-icon {
  height: 32px !important;
  width: 32px !important;
}

.medium-icon {
  height: 28px !important;
  width: 28px !important;
}

.small-icon {
  height: 18px !important;
  width: 18px !important;
}
