.shipping-services-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.2rem;

  .checkbox {
    cursor: pointer;
    margin-top: 0.2rem;
    height: 15px;
    width: 35px;
    border: 1px solid #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .selected {
    background-color: var(--color-primary);

    span {
      font-size: 0.65rem;
      margin-bottom: 1px;
      color: #fff;
    }
  }

  .not-selected {
    span {
      display: none;
    }
  }

  .details {
    margin-left: 0.8rem;

    span {
      font-size: 0.8rem;
    }

    .price {
      font-weight: 600;
      margin-left: 0.2rem;

      .arrow-icon {
        margin: 0 4px;
      }
      .old-price {
        text-decoration: line-through;
        font-weight: normal;
      }
    }
  }
}
