.btn {
  padding: 1rem 0;
  width: 100%;
  border: none;
  border-radius: 8px;

  color: #666666;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  font-size: 0.9rem;
  cursor: pointer;

  img {
    margin-top: 1px;
    height: 0.75rem;
    width: 0.75rem;
    margin-left: 1rem;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn-primary {
  background-color: #FCD64B;
}

.btn-danger {
  background-color: #c0392b;
  color: white;
}

.btn-secundary {
  border: 1px solid #FCD64B;
  background-color: transparent;
}
