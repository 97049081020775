.offer {
  @media(max-width: 1115px) {
    padding: 10px;
    display: flex;
    justify-content: center;
  }

  .ad {
    height: 126px;
    width: 730px;
    background-color: #FFEEF1;
    margin: 0 0 29px 0;
    border: 3px solid #CD201F;
    border-radius: 8px;

    @media(max-width: 1115px) {
      width: 100%;
      height: 177px;
    }

    .top {
      text-align: center;
      font-size: 18px;
      margin: 5px;
      font-weight: 500;
      line-height: 29px;
    }

    .mid {
      display: flex;
      justify-content: space-between;
      padding: 0 20px 0;
      align-items: baseline;

      @media(max-width: 1115px) {
        display: flex;
        padding: 0 20px 0;
        flex-direction: column;
        align-items: baseline;
      }

      .check {
        display: flex;
        align-items: baseline;

        input[type=checkbox] {
          position: relative;
          margin-right: 10px;
          cursor: pointer;
        }

        input[type=checkbox]:before {
          content: "";
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          top: -2px;
          left: -2px;
          box-shadow: 0 0px 2px 0px #cfcfcf;
          background-color: #e9e9e9;
          border-radius: 2px;
        }

        input[type=checkbox]:checked:before {
          content: "";
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          top: -2px;
          left: -2px;
          background-color: var(--color-primary);
          box-shadow: 0 0px 2px 0px #cfcfcf;
          border-radius: 2px;
        }

        input[type=checkbox]:checked:after {
          content: "";
          display: block;
          width: 2px;
          height: 7px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          position: absolute;
          top: 2px;
          left: 6px;
        }


        p {
          width: 281px;
          font-size: 12px;
        }
      }

      .midContent {
        display: flex;
        align-items: center;
        @media(max-width: 1115px) {
          display: flex;
          margin-left: 28px;
          align-items: flex-end;
        }

        .group {
          margin-right: 10px;

          span {
            color: #666666;
            font-size: 12px;
          }

          p {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .percentage {
          color: var(--color-primary);
          font-size: 12px;
          margin: 0;
        }
      }
    }

    .count {
      p {
        color: #EB5757;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        @media(max-width: 1115px) {
          margin-bottom: 0;
          margin-top: 35px;
        }
      }
    }
  }
}

.box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media(max-width: 1115px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 10px;
  }

  .data {
    height: auto;
    width: 730px;
    background: #FFFFFF;
    border-radius: 5px;

    @media(max-width: 1115px) {
      width: 100%;
      height: auto;
    }


    .header {
      background: #F2F2F2;
      height: 48px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;

      .firstPage {
        cursor: not-allowed;
        display: flex;
        align-items: center;
        opacity: 0.3;

        .number {
          width: 24px;
          height: 24px;
          background-color: #BDBDBD;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          font-size: 14px;
          font-weight: 500;
          margin-right: 6px;
        }

        span {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .partition {
        padding: 0 11px;
      }

      .secondPage {
        display: flex;
        align-items: center;

        .number {
          width: 24px;
          height: 24px;
          background-color: #BDBDBD;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          font-size: 14px;
          font-weight: 500;
          margin-right: 6px;
        }

        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .body {
      padding: 12px;

      .options {
        display: flex;
        gap: 25px;

        .option {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;
          width: 120px;
          height: 76px;
          border-radius: 6px;
          border: 1px solid #666666;
          background: none;
          color: #666666;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          background: white;
        }

        .active {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;
          width: 120px;
          height: 76px;
          border-radius: 6px;
          background: none;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          background: white;
          color: #3754DB;
          border-color: #3754DB;
        }
      }

      .boxData {
        .align {
          display: grid;
          grid-template-columns: 1fr 2fr;
          gap: 14px 40px;

          @media(max-width: 769px) {
            display: flex;
            flex-direction: column;
          }

          .information {
            p {
              color: #425466;
              font-weight: 500;
              font-size: 14px;
              margin-bottom: 8px;
            }

            input {
              width: 318px;
              height: 36px;
              border-radius: 6px;
              border: none;
              box-shadow: 0 1px 5px 0 #e3e1e1;
              padding: 0 0 0 8px;

              @media(max-width: 769px) {
                width: 100%;
              }
            }

            input:focus {
              outline: 0;
            }
          }
        }

        .formGroup {
          margin-top: 18px;
          display: flex;

          justify-content: space-between;
          align-items: flex-end;

          @media(max-width: 769px) {
            flex-direction: column;
            align-items: center;
          }

          p {
            font-size: 14px;
            color: #29272E;

            a {
              text-decoration: underline;
              cursor: pointer;
              color: #29272E;
            }
          }

          button {
            background-color: #FCD64B;
            border: none;
            width: 155px;
            height: 46px;
            border-radius: 8px;
            color: #666666;
            font-weight: 500;
            font-size: 14px;
            display: flex;
            cursor: pointer;
            align-items: center;
            gap: 5px;
            justify-content: center;

            @media(max-width: 769px) {
              width: 100%;
              margin-bottom: 10px;
            }
          }

          button:hover {
            box-shadow: 0 0 6px 0 #e3e1e1;
            background-color: #fcdd6e;
            transition: 1s;
          }
        }
      }

      .formGroup {
        margin-bottom: 18px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        @media(max-width: 769px) {
          flex-direction: column;
          align-items: center;
        }
      }

      .burgh {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 12px;
          color: #666666;

          @media(max-width: 1115px) {
            display: none;
          }
        }

        @media(max-width: 769px) {
          flex-direction: column;
          align-items: center;
        }
      }

      .btnBuy {
        background-color: #FCD64B;
        border: none;
        width: 155px;
        height: 46px;
        border-radius: 8px;
        color: #666666;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        cursor: pointer;
        align-items: center;
        gap: 5px;
        justify-content: center;

        @media(max-width: 769px) {
          width: 100%;
          margin-bottom: 10px;
        }

        .btnBuy:hover {
          box-shadow: 0 0 6px 0 #e3e1e1;
          background-color: #fcdd6e;
          transition: 1s;
        }
      }
    }

  }

  .summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 25px;

    @media(max-width: 1115px) {
      width: 100%;
      margin-left: 0;
      margin-bottom: 17px;
    }

    .boxSummary {
      height: auto;
      width: 350px;
      background: #FFFFFF;
      border-radius: 5px;
      @media(max-width: 1115px) {
        width: 100%;
      }
      .title {
        background: #F2F2F2;
        display: flex;
        height: 48px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        align-items: center;
        justify-content: space-around;

        p {
          color: #29272E;
          font-weight: 500;
          font-size: 14px;
        }

        .safety {
          width: 186px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--color-primary);
          border-radius: 5px;

          p {
            font-size: 14px;
            color: #fff;

            img {
              margin-right: 4px;
            }
          }
        }
      }

      table {
        display: flex;
        padding: 0 10px;
        flex-direction: column;

        tr {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 14px;

          .info {
            font-size: 10px;
            width: 160px;
          }

          td {
            font-size: 12px;
          }
        }
      }

      .discount {
        padding: 10px 10px 0;

        hr {
          width: 330px;
          margin: 0 auto;
          border-color: #F2F2F2;
          opacity: 0.2;
        }

        .agroupment {
          display: flex;
          justify-content: space-between;

          .strong {
            font-weight: 500;
            font-size: 12px;
          }

          p {
            font-size: 12px;
          }
        }
      }

      .totalPrice {
        margin-top: 10px;
        background: #F2F2F2;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        height: 75px;

        .alignPrice {
          display: flex;
          padding: 4px 10px 0;
          justify-content: space-between;
          align-items: center;

          p {
            font-size: 12px;
            font-weight: 500;
          }
        }

        .inCash {
          display: flex;
          justify-content: space-between;
          padding: 0 10px;

          span {
            color: #666666;
            font-size: 10px;
          }
        }
      }
    }

    .areaCupom {
      display: flex;
      cursor: pointer;
      flex-direction: column;

      .btn {
        gap: 6px;
        display: flex;
        font-size: 14px;

        .open {
          animation: rotateOpen 1s;
          transform: rotate(180deg);
        }

        .close {
          animation: rotateClose 1s;
          transform: rotate(360deg);
        }
      }

      .insert {
        animation: openCupom 1s;
        display: flex;
        align-items: center;
        gap: 12px;

        p {
          font-size: 12px;
          font-weight: 500;
        }

        input {
          width: 261px;
          height: 36px;
          border-radius: 10px;
          border: none;
          box-shadow: 0 1px 5px 0 #e3e1e1;
          padding: 0 0 0 8px;
        }

        input:focus {
          outline: 0;
        }

        .btnAplicar {
          display: inline-block;
        }

        .btnAplicar > button {
          width: 66px;
          height: 26px;
          border-radius: 7px;
          border: 1px solid #FCD64B;
          cursor: pointer;
          background: none;
          margin: -72px;
          color: #666666;
          font-weight: 500;
        }
      }
    }

    @keyframes openCupom {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @keyframes rotateOpen {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(180deg);
      }
    }

    @keyframes rotateClose {
      from {
        transform: rotate(180deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
