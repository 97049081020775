.address-container {
  padding: 0.5rem 0.8rem;
  color: #444;
  border-radius: 8px;
  border: 2px dotted #ccc;
  font-size: 0.875rem;

  &+& {
    margin-top: 1rem;
  }

  .align-icon {
    align-items: center;
  }

  .align-checkbox {
    align-items: flex-start;
  }
}

.address-content {
  display: flex;
  margin-bottom: 0.5rem;

  .checkbox {
    cursor: pointer;
    margin-right: 2px;
    margin-top: 0.5rem;
  }

  .checkbox-default {
    border: 2px solid #C9CED6;
    border-radius: 100%;
    padding: 0.6rem;
  }

  .checkbox-selected {
    border: 2px solid var(--color-primary);
    border-radius: 100%;
    padding: 0.2rem;

    div {
      padding: 0.41rem;
      border-radius: 100%;
      background-color: var(--color-primary);
    }
  }

  .address {
    display: flex;
    align-items: flex-start;
    padding: 0.5rem 1rem;
  }

  .address-default {
    border: 2px solid #f9f9f9;
  }

  .address-selected {
    border: 2px solid var(--color-primary);
  }

  img {
    height: 28px;
    width: 28px;

    @media (max-width: 992px) {
      height: 22px;
      width: 22px;
    }
  }

  .description {
    margin-left: 0.5rem;

    p {
      margin: 0.5rem;
    }

    p:first-child {
      font-size: 0.95rem;
      font-weight: 500;
    }

    p:not(:first-child) {
      font-weight: 400;
    }

    .receiver-information {
      display: flex;
      margin: 0 8px 8px;

      .receiver-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 25ch;
        display: block;
        overflow: hidden;
      }

      .receiver-phone::before {
        content: '\00a0 - ' !important;
      }

      @media (max-width: 768.99px) {
        flex-direction: column;

        .receiver-name {
          width: 30ch;
          margin-bottom: 8px;
        }

        .receiver-phone::before {
          content: '' !important;
        }
      }
    }

    @media (min-width: 768.99px) {
      .text-break::before {
        content: '- ';
      }
    }

    @media (max-width: 768px) {
      .text-break {
        margin-top: 0.5rem;
        display: flex;
      }
    }
  }
}

.address-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: flex;
    justify-content: flex-start;
    color: #3754DB;
    cursor: pointer;
  }

  span:not(.address-choose) {
    margin-left: 2.6rem;
    margin-right: auto !important;
  }

  img {
    margin-left: auto;
    cursor: pointer;
    margin-bottom: 4px;
  }

}
