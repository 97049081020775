.shipping-list-container {
  cursor: pointer;
  margin-bottom: 1rem;

  .checkbox {
    margin-right: 2px;
    margin-top: 0.5rem;
  }

  .checkbox-default {
    border: 2px solid #C9CED6;
    border-radius: 100%;
    padding: 0.6rem;
  }

  .checkbox-selected {
    border: 2px solid var(--color-primary);
    border-radius: 100%;
    padding: 0.2rem;

    div {
      padding: 0.4rem;
      border-radius: 100%;
      background-color: var(--color-primary);
    }
  }

  .shipping {
    display: flex;
    align-items: flex-start;
    padding: 0.5rem 1rem;
  }

  .shipping-default {
    border: 2px solid #f9f9f9;
  }

  .shipping-selected {
    border: 2px solid var(--color-primary);
  }

  .shipping-details {
    .text-bold {
      font-weight: 600;
    }

    p {
      font-size: 0.95rem;
      margin: 0.5rem;
    }

    p:first-child {
      font-weight: 500;
      color: #000;
    }

    p:not(:first-child) {
      font-weight: 400;
      color: #666666;
      line-height: 1.5rem;
      font-size: 0.875rem;
    }
    .old-price {
      font-weight: normal;
      text-decoration: line-through;
    }
  }
}
