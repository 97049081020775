input {
  font-weight: 500;
}

.label {
  font-weight: 500;
  font-size: 0.875rem;
  color: #425466;
  margin-bottom: 0.5rem;
  margin-left: 4px;
}

.error {
  color: red;
  display: flex;
  font-size: 0.625rem;
  padding-top: 0.5rem;
  margin-top:0px;
}

.input-form {
  display: flex;
  flex-direction: column;

  input {
    border: none;
    border-radius: 6px;
    font-size: 0.815rem;
    height: 40px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    padding-left: 8px;

    &:focus {
      outline: none;
    }
  }

  &+& {
    margin-top: 1.5rem;
  }
}

.input-zipcode-container {
  margin: 1.5rem 0;

  .input-zipcode {
    margin-top: 0.5rem;
    position: relative;
    width: 100%;

    input {
      width: 100%;
      position: relative;
      padding: 16px 0;
      border-radius: 10px;
      border: 1px solid #E7E7E7;
      padding-left: 0.5rem;
    }

    .btn-calculate {
      cursor: pointer;
      position: absolute;
      top: 7.5px;
      right: 0px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #FCD64B;
      width: auto;
      padding: 0 0.5rem;
      height: 2rem;

      span {
        font-size: 0.875rem;
      }
    }

    input:focus-visible {
      outline: none;
    }
  }
}
