.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);

  button {
    cursor: pointer;
  }
}

.container {
  width: 100%;
  max-width: 450px;
  margin: 0 16px;
  background: #fff;
  border-radius: 0.5rem;
  padding: 24px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);

  h1 {
    font-size: 22px;
  }

  p {
    margin-top: 8px;
  }
}

.footer {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .cancelButton {
    background: transparent;
    border: none;
    width: 100%;
    font-size: 16px;
    margin-right: 8px;
  }
}
