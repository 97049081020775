.modal {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 2000;
  justify-content: center;
  align-items: center;
  animation: animation .5s;

  @keyframes animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .recover {
    .content {
      .box {
        box-shadow: 3px 3px 8px 1px #00000042;
        display: flex;
        flex-direction: column;
        height: 90vh;
        background-color: white;
        width: 400px;
        border-radius: 5px;

        @media(max-width: 410px) {
          width: 320px;
        }
        @media(max-width: 290px) {
          width: 270px;
        }

        .iframe {
          width: 100%;
          height: 100%;
          border:none;
          border-radius: 10px;
        }
      }
    }
  }
}
