.container-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);

  img {
    height: 5rem;
    width: 5rem;

    @media (max-width: 768px) {
      height: 3.5rem;
      width: 3.5rem;
    }
  }

}
