.form{
  margin-top: 12px;
  .align{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px 40px;

    @media(max-width: 769px) {
      display: flex;
      flex-direction: column;
    }

    .onlyMobile{
      @media(max-width: 769px) {
        display: flex;
        gap: 20px;

        .inputMobile{
          width: 133px;
        }
      }
    }

    .information {
      p {
        color: #425466;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 8px;
      }

      input {
        width: 318px;
        height: 36px;
        border-radius: 6px;
        border: none;
        box-shadow: 0 1px 5px 0 #e3e1e1;
        padding: 0 0 0 8px;

        @media(max-width: 769px) {
          width: 100%;
        }
      }

      input:focus {
        outline: 0;
      }
    }
  }
  .information {
    p {
      color: #425466;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 8px;
    }

    select {
      width: 318px;
      height: 36px;
      border-radius: 6px;
      border: none;
      box-shadow: 0 1px 5px 0 #e3e1e1;
      padding: 0 0 0 8px;
      background: #fff;
      margin-bottom: 20px;

      @media(max-width: 769px) {
        width: 100%;
      }
    }

    select:focus {
      outline: 0;
    }
  }
}
