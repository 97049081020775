.box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media(max-width: 1115px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 10px;
  }

  .data {
    min-height: 395px;
    width: 730px;
    background: #FFFFFF;
    border-radius: 5px;
    height: auto;

    @media(max-width: 1115px) {
      width: 100%;
      height: auto;
    }


    .header {
      background: #F2F2F2;
      height: 48px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;

      .firstPage {
        display: flex;
        align-items: center;

        .number {
          width: 24px;
          height: 24px;
          background-color: #BDBDBD;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          font-size: 14px;
          font-weight: 500;
          margin-right: 6px;
        }

        span {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .partition {
        padding: 0 11px;
      }

      .secondPage {
        cursor: not-allowed;
        display: flex;
        align-items: center;
        opacity: 0.2;

        .number {
          width: 24px;
          height: 24px;
          background-color: #BDBDBD;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          font-size: 14px;
          font-weight: 500;
          margin-right: 6px;
        }

        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .body {
      padding: 12px;

      .boxData {
        .align {
          display: grid;
          grid-template-columns: 1fr 2fr;
          gap: 14px 40px;

          @media(max-width: 769px) {
            display: flex;
            flex-direction: column;
          }

          .information {
            p {
              color: #425466;
              font-weight: 500;
              font-size: 14px;
              margin-bottom: 8px;
            }

            input {
              width: 318px;
              height: 36px;
              border-radius: 6px;
              border: none;
              box-shadow: 0 1px 5px 0 #e3e1e1;
              padding: 0 0 0 8px;

              @media(max-width: 769px) {
                width: 100%;
              }
            }

            input:focus {
              outline: 0;
            }
          }
        }

        .formGroup {
          margin-top: 18px;
          display: flex;

          justify-content: space-between;
          align-items: flex-end;

          @media(max-width: 769px) {
            flex-direction: column;
            align-items: center;
          }

          p {
            font-size: 14px;
            color: #29272E;

            a {
              text-decoration: underline;
              cursor: pointer;
              color: #29272E;
            }
          }

          button {
            background-color: #FCD64B;
            border: none;
            width: 155px;
            height: 46px;
            border-radius: 8px;
            color: #666666;
            font-weight: 500;
            font-size: 14px;
            display: flex;
            cursor: pointer;
            align-items: center;
            gap: 5px;
            justify-content: center;

            @media(max-width: 769px) {
              width: 100%;
              margin-bottom: 10px;
            }
          }

          button:hover {
            box-shadow: 0 0 6px 0 #e3e1e1;
            background-color: #fcdd6e;
            transition: 1s;
          }
        }
      }
    }

  }

  .summary {
    height: auto;
    width: 350px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-left: 25px;

    @media(max-width: 1115px) {
      width: 100%;
      margin-left: 0;
      margin-bottom: 17px;
    }

    .title {
      background: #F2F2F2;
      display: flex;
      height: 48px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      align-items: center;
      justify-content: space-around;

      p {
        color: #29272E;
        font-weight: 500;
        font-size: 14px;
      }

      .safety {
        width: 186px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-primary);
        border-radius: 5px;

        p {
          font-size: 14px;
          color: #fff;

          img {
            margin-right: 4px;
          }
        }
      }
    }

    table {
      display: flex;
      padding: 0 10px;
      flex-direction: column;

      tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;

        .info {
          font-size: 10px;
          width: 160px;
        }

        td {
          font-size: 12px;
        }
      }
    }

    .discount {
      padding: 10px 10px 0;

      hr {
        width: 330px;
        margin: 0 auto;
        border-color: #F2F2F2;
        opacity: 0.2;
      }

      .agroupment {
        display: flex;
        justify-content: space-between;

        .strong {
          font-weight: 500;
          font-size: 12px;
        }

        p {
          font-size: 12px;
        }
      }
    }

    .totalPrice {
      margin-top: 10px;
      background: #F2F2F2;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      height: 75px;

      .alignPrice {
        display: flex;
        padding: 4px 10px 0;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 12px;
          font-weight: 500;
        }
      }

      .inCash {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;

        span {
          color: #666666;
          font-size: 10px;
        }
      }
    }

  }
}
.error{
  display: flex;
  color: red;
  font-size: 10px;
  padding: 7px;
}
