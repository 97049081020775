.shipping-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 1.75rem;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding:15px;
    h1 {
      padding: 0 1rem;
      font-size: 1.3rem;
      margin: 0;
    }
  }

}

.shipping-content {

  .send-to-text {
    display: flex;
    color: #000;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .digital-product-text {
    margin: 2rem 0;
    font-size: 0.85rem;
    color: #666;
    line-height: 1.45rem;
    font-weight: 400;
  }
}

.shipping-list {
  margin-top: 1.8rem;
}
