.content {
  position: relative;

  background-color: #fff;
  border-radius: 5px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */


  margin: 3rem 0 1rem;
  padding: 1.5rem 1.5rem;
  max-width: 400px;
  width: 100%;

  @media (max-width: 768px) {
    margin: 3.5rem 0 1rem;
  }

  .back-page {
    cursor: pointer;
    position: absolute;

    top: -38px;
    left: 0;
    font-weight: 400;
    font-size:14px;
    color:#29272E;
    padding: 0.5rem;
    margin-right: 1rem;

    img {
      margin-right: 8px;
    }
  }
}
