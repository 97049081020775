@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
  font-family: "Roboto", sans-serif;
}

.login {
  padding-bottom: 10px;
  text-align: center;

  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
  }
}
.separateDivs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media(max-width: 769px) {
    justify-content: flex-start;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:100px;

    .boxLogin {
      background-color: #fff;
      width: 375px;
      height: 287px;
      margin: 12px 20px;
      border-radius: 5px;
      padding: 1px;

      @media(max-width: 768px) {
        width: 350px;
      }

      @media(max-width: 360px) {
        width: 300px
      }

      h3 {
        text-align: center;
        font-size: 20px;
        padding-top: 30px;
      }

      .logo {
        display: block;
        margin-bottom: 15px;
      }

      h1 {
        margin: 0;
        width: 313px;
        font-size: 22px;
        font-weight: normal;
        line-height: 1.5;
        text-align: center;
        color: #4a4a4a;
        margin-bottom: 29px;
      }

      .align {
        margin-left: 29px;
        margin-top: 35px;
        @media(max-width: 769px) {
          display: flex;
          flex-direction: column;
          align-content: center;
          margin: 0 15px;
        }

        p {
          margin-top: 24px;
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 14px;
          color: #425466;
        }

        input {
          width: 308px;
          height: 36px;
          border: none;
          border-radius: 6px;
          font-weight: 500;
          font-size: 13px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
          padding-left: 9px;

          @media(max-width: 769px) {
            max-width: 308px;
            width: 95%;
          }
        }

        input:focus {
          outline: 0;
        }

        .password {
          position: relative;
          display: flex;
          align-items: center;

          img {
            position: absolute;
            right: 35px;
            cursor: pointer;
            @media(max-width: 769px) {
              right: 15px;
            }
          }
        }
      }

      .formGroup {
        display: flex;
        flex-direction: column;
        margin: 31px 0 18px;
        align-items: center;

        button {
          width: 131px;
          height: 46px;
          border-radius: 8px;
          border: none;
          cursor: pointer;
          background-color: #FCD64B;
          font-size: 14px;
          font-weight: 500;
          color: #666666;
          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        button:hover {
          background: #ffe68a;
          transition: all 0.3s;
        }
      }

      .group {
        display: flex;
        flex-direction: column;
        align-items: center;

        button.remeber {
          border: none;
          background: none;
          color: #666666;
          font-size: 12px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .outsideFormGroup {
      display: flex;
      flex-direction: column;
      margin-top: 31px;
      align-items: center;

      a {
        width: 131px;
        height: 46px;
        border-radius: 8px;
        background-color: transparent;
        cursor: pointer;
        border: 1px solid #fcd64b;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a:hover {
        background: #ffe68a;
        transition: all 0.3s;
      }
    }
  }

  .footer {
    margin-top: 32px;
  }
}
