.cart-container {
  max-width: 1111px;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 100px - 62px);

  h1 {
    padding-top: 2rem;
  }

  @media (max-width: 992px) {
    max-width: 100%;

    height: 100%;

    h1 {
      padding-top: 0;
      font-size: 1.5rem;
      padding: 0 1rem;
    }
  }
}

.cart-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 992px) {
    flex-direction: column;
    margin: 0 1rem;
  }

  .cart-list {
    width: 70%;

    @media (max-width: 992px) {
      width: 100%;
    }

    .cart-list__header {
      margin-bottom: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .items {
        padding-left: 2rem;
        width: 50%;
      };

      span {
        color: #666666;
        font-weight: 300;
        font-size: 0.9rem;
      }

      span:last-child {
        margin-right: 3rem;
        margin-left: 5rem;
      }

      @media (max-width: 992px) {
        display: none;
      }
    }
  }

  .cart-resume {
    width: 30%;
    margin-left: 2.5rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
      width: 100%;
      margin: 0
    }

    .keep-buying {
      text-decoration: none;
      margin-left: auto;
      margin-bottom: 0.8rem;
      color: var(--color-primary);
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
}
