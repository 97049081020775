.cart-resume-container {
  position: relative;

  button {
    cursor: pointer;
  }

  background-color: #fff;
  border-radius: 5px;
  padding: 1rem 0.75rem;
  font-size: 0.9rem;

  .loading-overlay {
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 5rem;
      width: 5rem;
    }
  }

  .total-content, .subtotal-content, .shipping {
    padding: 1.325rem 0 1.325rem 0.5rem;
  }

  h4 {
    margin: 0;
    color: #29272E;
    font-weight: 500;
    padding-bottom: 0.8rem;
  }

  .divisor {
    background-color: #EDF2F7;
    height: 1px;
    width: 100%;
  }

  .subtotal-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span:first-child {
      color: #666666;
    }

    span:last-child {
      color: #29272E;
      font-weight: 600;
    }
  }

  .shipping {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666666;

    .shipping-calculate {
      position: relative;

      input {
        padding: 12px 24px 12px 16px;
        border-radius: 10px;
        border: 1px solid #E7E7E7;
      }

      .btn-calculate {
        position: absolute;
        top: 3.5px;
        right: 5px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #FCD64B;
        width: 4rem;
        height: 1.9rem;

        span {
          font-size: 0.875rem;
        }
      }

      input:focus-visible {
        outline: none;
      }
    }
  }

  .total-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .total-resume {

      h6 {
        margin: 0;
      }

      h6:first-child, h6.value {
        text-align: end;
        font-size: 0.9rem;
        min-width: 83px;
      }

      h6.installment {
        margin-top: 2px;
        font-size: 0.75rem;
        color: #666666;
        font-weight: 400;
      }
    }
  }

  .btn-continue {
    padding: 1rem 0;
    width: 100%;
    border: none;
    border-radius: 8px;

    background-color: #FCD64B;
    color: #666666;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    font-size: 0.9rem;
    cursor: pointer;

    img {
      margin-top: 1px;
      height: 0.75rem;
      width: 0.75rem;
      margin-left: 1rem;
    }
  }
}
