@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  background-color: #F7F7F7;
}

.separateDivs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  @media(max-width: 769px) {
    justify-content: flex-start;
  }

  .content {
    display: flex;
    justify-content: center;

    .box {
      background-color: #fff;
      width: 405px;
      height: 260px;
      border-radius: 5px;

      @media(max-width: 769px) {
        width: 95%;
        margin-top: 100px;
      }

      h3 {
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        padding-top: 25px;
        padding-bottom: 14px;

        @media(max-width: 769px) {
          padding: 7px 10px 14px;
        }
      }

      .formGroup {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin-bottom: 25px;

        .btnNew {
          width: 100%;
          height: 46px;
          max-width: 320px;
          border-radius: 5px;
          background-color: #fcd64b;
          display: flex;
          margin-bottom: 24px;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 14px;
          color: #5a5e5c;
          transition: all 0.3s;
          cursor: pointer;
          text-decoration: none;

          @media(max-width: 769px) {
            max-width: 298px;
          }
        }

        .btnNew:hover {
          background: #ffe68a;
          transition: all 0.3s;
        }

        .btnOld {
          width: 100%;
          height: 46px;
          max-width: 320px;
          border: 1px solid #fcd64b;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 14px;
          color: #5a5e5c;
          transition: all 0.3s;
          cursor: pointer;
          text-decoration: none;

          @media(max-width: 769px) {
            max-width: 298px;
          }
        }

        .btnOld:hover {
          background: #ffe68a;
          transition: all 0.3s;
        }

      }
    }
  }
}