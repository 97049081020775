.modal {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 2000;
  justify-content: center;
  align-items: center;
  animation: animation .5s;

  @keyframes animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .recover {
    .content {
      .boxLogin {
        box-shadow: 3px 3px 8px 1px #00000042;
        display: flex;
        flex-direction: column;
        height: 310px;
        background-color: white;
        width: 350px;
        border-radius: 5px;

        .close {
          width: 20px;
          margin: 5px 323px;
          cursor: pointer;
        }

        h4 {
          font-weight: 500;
          font-size: 18px;
          text-align: center;
          margin: 13px 0 0;
        }

        span {
          text-align: center;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          padding: 21px 12px 0;
        }

        p {
          padding: 14px 13px 0;
          margin: 0;
          font-weight: 500;
          font-size: 14px;
        }

        input {
          margin: 10px 13px;
          width: 308px;
          height: 36px;
          border: none;
          border-radius: 6px;
          font-weight: 500;
          font-size: 13px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
          padding-left: 9px;
          margin-bottom: 21px;
        }

        input:focus {
          outline: 0;
        }

        .formGroup {
          text-align: center;

          button {
            height: 46px;
            width: 155px;
            background-color: #FCD64B;
            border-radius: 8px;
            border: none;
            font-weight: 500;
            font-size: 14px;
            color: #666666;
            cursor: pointer;
            &:disabled {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
